import { useDispatch } from 'react-redux';
import useStore from '../store/selectors/useStore';
import { setActiveCountry } from '../store/slices/countriesSlice';
import '../utils/i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface propsProjectsFilterCountry {
	className?: string;
}

function ProjectsFilterCountry({ className }: propsProjectsFilterCountry) {
	const { countries, activeCountry } = useStore();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	
	let properText = useCallback((country) => {
		if(!country) {
			return t('main.projects.all')
		}
			return country
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<ul className={`projects-filter-country ${className}`}>
			{countries.map((country) => (
				<li key={country.id} className="projects-filter-country__item">
					<span
						onClick={() => dispatch(setActiveCountry(country?.country?.id))}
						className={`projects-filter-country__link ${
							activeCountry === country.country?.id ? 'active' : ''
						}`}
					>
						{properText(country.name)}
					</span>
				</li>
			))}
		</ul>
	);
}

export default ProjectsFilterCountry;
