import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import AboutFile from '../components/AboutFile';
import AboutInfo from '../components/AboutInfo';
import Management from '../components/Management';
import AboutRegularInfo from '../components/AboutRegularInfo';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function About() {
	const { t } = useTranslation();
	useDocumentTitle(t('about.title'));

	return (
		<div className="about">
			<ScrollToTopOnMount />
			<div className="about__body">
				<AboutInfo className="about__info" />
				<Management titleSize="middle" className="about__management" />
				<AboutRegularInfo className="about__regular-info" />
				<AboutFile className="about__file" />
			</div>
		</div>
	);
}

export default About;
