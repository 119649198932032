import React, { useEffect, useState, useCallback } from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import useStore from '../store/selectors/useStore';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Link, useLocation } from 'react-router-dom';
import { post } from '../typings/search';
import useSearch from '../services/service.search';
import Pagination from '../components/Pagination';
import { getLocaleProperty } from '../utils/getLocaleProperty';

function Search() {
	const { t } = useTranslation();
	useDocumentTitle(t('search.title'));

	const { language } = useStore();
	const { getAllSearch } = useSearch();
	const search = useLocation().search;
	const searchRequest = new URLSearchParams(search).get('search');
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [searchResponse, setSearchResponse] = useState<post[]>([]);

	const [pagination, setPagination] = useState({
		currentPage: 0,
		lastPage: 0,
		total: 0,
	});

	let searchType = (type) => {
		if(type === 'article') {
			return 'news'
		}
		if (type === 'vacancy') {
			return 'vacancies'
		} 

		if (type === 'project') {
			return 'projects'
		}
	}
	const from =
		pagination.currentPage === 1
			? pagination.total !== 0
				? 1
				: 0
			: (pagination.currentPage - 1) * 8 + 1;

	const to =
		pagination.currentPage === pagination.lastPage
			? pagination.total
			: pagination.currentPage * 8;

	const onPageChange = useCallback(
		(page: number) => {
			if (searchRequest) {
				getAllSearch(searchRequest, language, page).then((data: any) => {
					const { items, pagination } = data;
					if(pagination.lastPage < pagination.currentPage) {
						getAllSearch(searchRequest, language, pagination.lastPage).then((correctData: any) => {
							const { items: itemsMaxPage, pagination: paginationMaxPage } = correctData;
							setSearchResponse(itemsMaxPage);
							setPagination(paginationMaxPage);
							setIsVisible(true);
						})
					}  else {
						setSearchResponse(items);
						setPagination(pagination);
						setIsVisible(true);
					}
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language, searchRequest]
	);

	return (
		<div className="search-page">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="search-page__body">
					<Title size="middle" className="search-page__title-page">
						{t('search.title')}
					</Title>
					<div className="search-page__info">
						{Boolean(searchRequest) && searchResponse?.length !== 0 ? (
							<span>
								{t('search.results.first-part')}{' '}
								<strong>
									{from === -7 ? 0 : from}-{to}
								</strong>{' '}
								{t('search.results.second-part')}{' '}
								<strong>{pagination.total}</strong>
							</span>
						) : (
							<span />
						)}
					</div>
					<div className="search-page__results">
						{searchResponse?.length !== 0 ? (
							searchResponse?.map((post) => (
								<div
									className="search-page__item"
									key={`${post.slug}-${post.title_en}`}
								>
									<p className="search-page__title">
										<Link to={`/${searchType(post.type)}/${post.slug}`}>
											{post.title}
										</Link>
									</p>
									<div
										className="search-page__description"
										contentEditable="false"
										dangerouslySetInnerHTML={{
											__html: post.body,
										}}
									/>
								</div>
							))
						) : (
							<p>{t('search.results.empty')}</p>
						)}
						{Boolean(searchRequest) && (
							<Pagination
								maxPage={pagination.lastPage}
								onPageChange={onPageChange}
								isVisible={isVisible}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Search;
