import React, { useEffect, useState } from 'react';
import ButtonLink from './ButtonLink';
import { NEWS } from '../helpers/routes';
import useRedirectToItem from '../hooks/useRedirectToItem';
import useNews from '../services/service.news';
import { news, propsMainNewsPriority } from '../typings/news';
import useStore from '../store/selectors/useStore';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { imageSrc } from '../helpers/imageSrc';

function MainNewsPriority({ className }: propsMainNewsPriority) {
	const { language } = useStore();
	const [mainNews, setMainNews] = useState<any>();
	const { getPinnedNews } = useNews();
	const redirect = useRedirectToItem();
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;

	let imgSrc = imageSrc();
	let src = (mainNews) => {
		if (mainNews) {
			return `${mainNews?.image[imgSrc]?.path}`;
		}
		return '';
	};

	const { t } = useTranslation();

	useEffect(() => {
		if (language) {
			getPinnedNews(language).then((pinnedNews) => {
				setMainNews(pinnedNews.articles[0]);
			});
		}
	}, [language]);

	return (
		<div className={`main-news-priority ${className}`}>
			<div
				className="main-news-priority__body"
				onClick={() => redirect(NEWS, mainNews?.slug)}
			>
				{src(mainNews) && (
					<>
						<span className="main-news-priority__date">
							{mainNews?.article?.updatedAt
								.slice(0, 10)
								.split('-')
								.reverse()
								.join('-')
								.replaceAll('-', '.') || ''}
						</span>
						<h3 className="main-news-priority__title">
							{mainNews?.title || ''}
						</h3>
						<div className="main-news-priority__image">
							{src(mainNews) !== '' && (
								<img
									src={src(mainNews) ? `${_baseUrl}${src(mainNews)}` : ''}
									alt=""
								/>
							)}
						</div>
						<div
							className="main-news-priority__description"
							contentEditable="false"
							dangerouslySetInnerHTML={{
								__html: `${mainNews?.body || ''}`,
							}}
						/>
						<ButtonLink
							type="button"
							className="main-news-priority__button"
							color="blue"
						>
							{t('main.buttons.read-more')}
						</ButtonLink>
					</>
				)}
			</div>
		</div>
	);
}

export default MainNewsPriority;
