import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import VacanciesList from '../components/VacanciesList';
import useVacancies from '../services/service.vacancies';
import useStore from '../store/selectors/useStore';
import { setVacancies } from '../store/slices/vacanciesSlice';
import { vacancy } from '../typings/vacancies';
import { setVacancy  } from '../store/slices/vacancySlice';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import useBindActionCreators from '../hooks/useBindActionCreators';
import { languageActions } from '../store/slices/languageSlice';
import { setTitle } from '../store/slices/titleSlice';

function VacanciesInside() {
	const { vacancies, language, vacancy } = useStore();
	const boundCreators = useBindActionCreators({ ...languageActions });
	const { setLanguage } = boundCreators;
	const { getVacancy, getAllVacancies } = useVacancies();
	const { id: vacancyId } = useParams();
	let translationSlug
	
	const [currentVacancy, setCurrentVacancy] = useState<vacancy>({
		slug: '',
		title: '',
		body: '',
		image: {
			small: {
				path: ''
			},
			medium: {
				path: ''
			},
			large: {
				path: ''
			},
		},
		language: {
			code: 'ua'
		},
		vacancy: {
			translations: [
				{
					slug: "",
					"language": {
						"code": "ua"
					}
				},
			]
		}
	});
	const dispatch = useDispatch();
	const { t } = useTranslation();

	let vacanciesExceptCurrent = vacancies
	.slice(0, 5)
	.filter((item) => item.slug.toString() !== vacancyId);

	useEffect(() => {
		if (vacancyId  && language && !currentVacancy.slug ) {
			getVacancy(vacancyId as string, language).then((data) => {
				setCurrentVacancy(data)
				dispatch(setTitle(data.title))
			});
		}
	}, [language, vacancyId]);

	
	useEffect(() => {
		if (language && (vacancies.length === 0 || vacancies[0].language.code !== language)) {
			getAllVacancies(language, 'uhp').then((data) => {
				dispatch(setVacancies(data.vacancies))}
			);
		}
	}, [language]);

	let checkIfLanguageCreated = (currentVacancy : vacancy) => {
		return currentVacancy.vacancy?.translations?.some((el: { language: { code: string; }; }) => 
			el.language.code === language)
	}	

		useEffect(() => {
		if(language !== currentVacancy?.language?.code && currentVacancy && checkIfLanguageCreated(currentVacancy)) {
			translationSlug = currentVacancy?.vacancy?.translations?.filter((el: { language: { code: string; }, slug: string }) => {
				if (el.language.code === language) {
					return el
				}
			})
			getVacancy(`${translationSlug[0]?.slug ? translationSlug[0]?.slug : currentVacancy.slug }`, language).then((data) => {
				setCurrentVacancy(data)
				setVacancy(data)
				dispatch(setTitle(data.title))
			})
			
			window.history.replaceState( null , '', `${translationSlug[0]?.slug ? translationSlug[0]?.slug : currentVacancy.slug }`);
		}
		
	}, [getVacancy, language]);

	useEffect(() => {
		return () => {
			dispatch(setTitle(""))
		}
	}, [])
	
	return (
		<section className="vacancies-inside">
			<ScrollToTopOnMount />
			<div className="container">
				<Title size="middle" className="vacancies-inside__title">
					{currentVacancy.title}
				</Title>
				<div className="vacancies-inside__body">
					<div
						className="vacancies-inside__content"
						contentEditable="false"
						dangerouslySetInnerHTML={{
							__html: currentVacancy.body,
						}}
					/>
					<aside className="vacancies-inside__aside">
						<Title size="small" className="vacancies-inside__aside-title">
							{t('vacancies.other-vacancies')}
						</Title>
						<VacanciesList
							data={vacanciesExceptCurrent}
							setCurrentVacancy={setCurrentVacancy}
							className="vacancies-inside__vacancies-list vacancies-list--aside"
						/>
					</aside>
				</div>
			</div>
		</section>
	);
}

export default VacanciesInside;
