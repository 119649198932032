import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import ServicesEngineering from '../components/ServicesEngineering';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Services() {
	const { t } = useTranslation();

	useDocumentTitle(t('services.title'));

	return (
		<div className="services">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="services__body">
					<Title size="middle" className="services__title">
						{t('services.title')}
					</Title>
					<div className="services__info">
						<div className="services__text">{t('services.desc')}</div>
					</div>
					<ServicesEngineering />
				</div>
			</div>
		</div>
	);
}

export default Services;
