import React, { useState } from 'react';
import { tabs } from '../data/tabs';
import useRedirectToItem from '../hooks/useRedirectToItem';
import ButtonLink from './ButtonLink';
import Title from './Title';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsMainAboutTabs {
	className?: string;
}

function MainAboutTabs({ className }: propsMainAboutTabs) {
	const [toggleState, setToggleState] = useState(0);
	const redirect = useRedirectToItem();
	const { t } = useTranslation();

	const translateTabs = t('main.about-tabs.tabs', {
		returnObjects: true,
	});

	const infoList = t('main.info-list', {
		returnObjects: true,
	});

	return (
		<div className={`main-about-tabs ${className}`}>
			<div className="container">
				<Title size="middle" className="main-about-tabs__title">
					{t('main.about-tabs.title')}
				</Title>
				<div className="main-about-tabs__body">
					<div className="main-about-tabs__nav">
						{Array.from(translateTabs).map((tab: any, index: number) => (
							<button
								key={index}
								onClick={() => setToggleState(index)}
								className={
									toggleState === index
										? 'main-about-tabs__nav-button active'
										: 'main-about-tabs__nav-button'
								}
							>
								<span>{'0' + (index + 1)}</span>
								<span>{tab.title}</span>
							</button>
						))}
					</div>

					<ul className="main-about-tabs__content">
						{Array.from(translateTabs).map((tab: any, index: number) => {
							return (
								<li
									key={index}
									className={
										toggleState === index
											? 'main-about-tabs__content-item active'
											: 'main-about-tabs__content-item'
									}
								>
									<div className="main-about-tabs__content-left">
										<div className="main-about-tabs__content-text">
											<h6>
												{t('main.title.type')} {t('main.title.name')}
											</h6>
											<div
												className="main-about-tabs__item"
												contentEditable="false"
												dangerouslySetInnerHTML={{
													__html: `${tab.p1}`,
												}}
											/>
											<div
												className="main-about-tabs__item"
												contentEditable="false"
												dangerouslySetInnerHTML={{
													__html: `${tab.p2}`,
												}}
											/>
										</div>
										{index !== 2 && (
											<ButtonLink
												type="button"
												color="blue"
												className="main-about-tabs__content-button"
												onClick={() =>
													index === 0 ? redirect('about') : redirect('services')
												}
											>
												{t('main.buttons.read-more')}
											</ButtonLink>
										)}
									</div>
									<div className="main-about-tabs__content-right">
										{/* <div className="main-about-tabs__content-image">
											<img src={tabs[index].image} alt="" />
										</div> */}
										<ul className="main-about-tabs__list">
											{Array.from(infoList).map((item: any) => {
												return (
													<li
														key={item.num}
														className="main-about-tabs__item-list"
													>
														<span className="main-about-tabs__item-num">
															{item.num}+
														</span>
														<span className="main-about-tabs__item-text">
															{item.text}
														</span>
													</li>
												);
											})}
										</ul>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default MainAboutTabs;
