import React from 'react';
import ContactFax from './ContactFax';
import ContactMarketing from './ContactMarketing';
import ContactReception from './ContactReception';
import ContactSocial from './ContactSocial';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactInfo {
	className?: string;
}

function ContactInfo({ className }: propsContactInfo) {
	const { t } = useTranslation();

	return (
		<div className={`${className} contact-info`}>
			<div className="contact-info__body">
				<address className="contact-info__address">
					{t('footer.contact.address.first-part')}
					<br /> {t('footer.contact.address.second-part')}
				</address>
				<div className="contact-info__row">
					<ContactReception className="contact-info__reception" />
					<ContactMarketing className="contact-info__marketing" />
				</div>
				<ContactFax className="contact-info__row" />
			</div>
		</div>
	);
}

export default ContactInfo;
