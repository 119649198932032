import React, { useEffect } from 'react';
import useBindActionCreators from '../hooks/useBindActionCreators';
import useCategories from '../services/service.categories';
import useStore from '../store/selectors/useStore';
import { categoriesActions } from '../store/slices/categoriesSlice';
import { filterProjectsByCategory } from '../store/slices/projectsSlice';
import { category, propsProjectsDropdownList } from '../typings/categories';
import { project } from '../typings/projects';
import '../utils/i18next';

function ProjectsDropdownList({
	className,
	closeDropdown,
}: propsProjectsDropdownList) {
	const { categories, activeCategory, language } = useStore();
	const getAllCategories = useCategories();
	const {
		setCategories,
		setActiveCategory,
		filterProjectsByCategory: filterProjects,
	} = useBindActionCreators({ ...categoriesActions, filterProjectsByCategory });

	useEffect(() => {
		if(language) {
			getAllCategories(language).then((categories) => {
				setCategories(categories);
				if (categories.categories.length !== 0) {
					setActiveCategory(
						(activeCategory.name !== '' && activeCategory.category?.id) ?
							categories.categories.find(
								(project: any) =>
									project?.category?.id === activeCategory?.category?.id) : 
					categories.categories[0]
					);
				}
			});
		}
	}, [language]);


	const onCategoryChange = (category: category) => {
		setActiveCategory(category);
		closeDropdown();
		filterProjects(category);
	};

	if (activeCategory?.name === '') {
		return <span />;
	}

	return (
		<ul className={`projects-dropdown-list ${className}`}>
			{categories?.categories?.map((item: any) => (
				<li className="projects-dropdown-list__item" key={item.id}>
					<span
						onClick={() => onCategoryChange(item)}
						className="projects-dropdown-list__link"
						style={{ cursor: 'pointer' }}
					>
						{item.name}
					</span>
				</li>
			))}
		</ul>
	);
}

export default ProjectsDropdownList;
