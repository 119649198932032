import React from 'react';
import iconFacebook from '../img/icons/social/facebook.svg';
import iconInstagram from '../img/icons/social/instagram.svg';
import iconYoutube from '../img/icons/social/youtube.svg';
import iconGoogle from '../img/icons/social/google.svg';

interface propsContactSocial {
	className?: string;
}

function ContactSocial({ className }: propsContactSocial) {
	return (
		<ul className={`contact-social ${className}`}>
			<li className="contact-social__item">
				<a
					href="https://www.facebook.com/Virtual-Design-Bureau-Ukraine-106887037789802"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconFacebook}
						alt="facebook"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.instagram.com/ukrhydroproject/"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconInstagram}
						alt="instagram"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.youtube.com/channel/UCGb_695qQu15YUXLPIn9yNg"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconYoutube}
						alt="youtube"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.google.com/search?q=%D0%9F%D0%A0%D0%90%D0%A2+%22%D0%A3%D0%9A%D0%A0%D0%93%D0%86%D0%94%D0%A0%D0%9E%D0%9F%D0%A0%D0%9E%D0%95%D0%9A%D0%A2%22+-+UKRHYDROPROJECT+PRJSC"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img src={iconGoogle} alt="google" className="contact-social__logo" />
				</a>
			</li>
		</ul>
	);
}

export default ContactSocial;
