import { useEffect, useState, useRef } from 'react';
import useDocuments from '../services/service.documents';
import { documents } from '../typings/documents';
import MoreControls from './MoreControls';
import Title from './Title';
import useStore from '../store/selectors/useStore';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsAboutRegularInfo {
	className?: string;
}

function AboutRegularInfo({ className }: propsAboutRegularInfo) {
	const { language } = useStore();
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	const { getAllDocuments } = useDocuments();
	const [regularInfo, setRegularInfo] = useState<any>();

	const documentHandlerHeight = useRef(null);
	const [handlerHeight, setHandlerHeight] = useState(Number);

	const [openedRegularInfo, setOpenedRegularInfo] = useState<documents | null>(
		null
	);

	const { t } = useTranslation();

	const displayedRegularInfo = openedRegularInfo?.map((item: any) => (
		<li key={item.id} className="about-regular-info__item">
			{/* <span className="about-regular-info__date">
				{item.document.createdAt
					.slice(0, 10)
					.split('-')
					.reverse()
					.join('-')
					.replaceAll('-', '.')}
			</span> */}
			<h6 className="about-regular-info__doc-title">
				{item.name}
			</h6>
			<a
				download
				href={`${_baseUrl}${item?.file?.path}`}		
				target="_blank"
				rel="noreferrer"
				className="about-regular-info__button button-link button-link--blue"
			>
				{t('about.buttons.download')}
			</a>
		</li>
	));

	useEffect(() => {
		if(language) {
			getAllDocuments(language, 'regular_information').then((data: any) => {
				let regularInfoFiltered = data.documents.filter((document: any) => document.document.type === 'regular_information')
				setRegularInfo(regularInfoFiltered);
				setOpenedRegularInfo(
					regularInfoFiltered?.length >= 4 ? regularInfoFiltered.slice(0, 4) : regularInfoFiltered
				);
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<div className={`about-regular-info ${className}`}>
			<div className="container">
				<div id="regular-information" className="about-regular-info__body">
					<Title size="middle">{t('about.regular-info.title')}</Title>

					<ul
						className="about-regular-info__list"
						style={{ height: handlerHeight + 10 }}
					>
						<div
							ref={documentHandlerHeight}
							className="about-regular-info__handler"
						>
							{displayedRegularInfo}
						</div>
					</ul>
					{regularInfo && (
						<MoreControls
							handlerRef={documentHandlerHeight}
							openedItems={openedRegularInfo}
							handleOpenedItems={setOpenedRegularInfo}
							items={regularInfo}
							handleBlockHeight={setHandlerHeight}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default AboutRegularInfo;
