import React from 'react';
import { Link} from 'react-router-dom';
import routes from '../helpers/routes';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useStore from '../store/selectors/useStore';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';


const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs(routes);
	const { activeCategory, language, title } = useStore();

	return (
		<div className="container">
			<ul
				className={breadcrumbs.length > 1 ? 'breadcrumbs' : 'breadcrumbs none'}
			>
				{breadcrumbs.map(({ match, breadcrumb }, index) => {
					return (
						<li key={`${match.pathname}-${index}`}>
							<Link to={match.pathname}>
								{index === 2
									? title
									: getLocaleProperty(match.route, 'breadcrumb', language)}
							</Link>
							{match.pathname === '/projects' && (
								<Link to="/projects">
									{activeCategory?.name}
								</Link>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Breadcrumbs;
