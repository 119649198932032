import React from 'react';

interface propsTable {
	data?: {
		key: string;
		value: string;
	}[];
}

function Table({ data }: propsTable) {
	return (
		<div className="table">
			{data?.map((content, index) => (
				<div key={index} className="table__row">
					<h6 className="table__col table__col--title">{content?.key}</h6>
					<span className="table__col table__col--info">{content?.value}</span>
				</div>
			))}
		</div>
	);
}

export default Table;
