import { createSlice } from "@reduxjs/toolkit";
import {  vacancy } from "../../typings/vacancies";

const initialState: vacancy = {
    vacancy: {
        translations: []
    },
    slug: '',
    title: '',
    body: '',
    language: {
        code: ''
    },
    image: {
        small: {
            path: ''
        },
        medium: {
            path: ''
        },
        large: {
            path: ''
        }
    },
};



const vacancySlice = createSlice({
    name: 'vacancy',
    initialState,
    reducers: {
        setVacancy: (state, action) => {
            state.vacancy = action.payload;
        }
    }
});


const { actions, reducer: vacancyReducer } = vacancySlice;
export const { setVacancy } = actions;
export default vacancyReducer;
