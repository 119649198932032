import React from 'react';
import { Link } from 'react-router-dom';
import ArrowLink from './ArrowLink';
import { propsProjectsCountryItem } from '../typings/projects';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';
import useStore from '../store/selectors/useStore';
import '../utils/i18next';
import { imageSrc } from '../helpers/imageSrc';

function ProjectsCountryItem({ data }: propsProjectsCountryItem) {
	const { language } = useStore();

	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`

	let imgSrc = imageSrc(true)
	let src = (project) => {
		return `${project?.images[0]?.image[imgSrc]?.path}`
	}
	return (
		<>
			{data.map((project, index) => (
				<li key={index} className={`projects-country-item`}>
					<Link className="projects-country-item__link" to={`${project.slug}`}>
						<div className="projects-country-item__image">
							{project?.images[0]?.image?.original?.path ? (
								<img
									src={`${_baseUrl}/${src(project)}` || projectEmptyPhoto}
									alt={'project'}
								/>
							) : (
								<span className="projects-country-item__no-image" />
							)}
						</div>
						<h4 className="projects-country-item__title">
							{project.title}
						</h4>
						<ArrowLink className="projects-country-item__arrow" />
					</Link>
				</li>
			))}
		</>
	);
}

export default ProjectsCountryItem;
