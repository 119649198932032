import photo1 from '../img/management/1.jpg';
import photo2 from '../img/management/2.jpg';
import photo3 from '../img/management/3.jpg';
import photo4 from '../img/management/4.jpg';
import photo5 from '../img/management/5.jpg';

export const management = [
	{
		photo: photo1,
		fullName: 'Кривицький Володимир Володимирович',
		position: '1-й заступник голови наглядової ради',
	},
	{
		photo: photo2,
		fullName: 'Осадчий Сергій Дмитрович',
		position: 'Генеральний директор',
	},
	{
		photo: photo3,
		fullName: 'Крайник Вадим Ярославович',
		position: '1-й заступник Голови правління',
	},
	{
		photo: photo4,
		fullName: 'Погосян Сергій Миколайович',
		position: 'Директор технічний',
	},
	{
		photo: photo5,
		fullName: 'Ночовкін Юрій Олександрович',
		position: 'Директор з маркетингу',
	},
]
