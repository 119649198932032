export const managementSliderSettings = {
	slidesPerView: 1,
	centeredSlides: false,
	spaceBetween: 20,
	loop: false,
	speed: 500,
	allowTouchMove: true,
	pagination: {
		clickable: true,
		bulletElement: 'span',
		clickableClass: 'bullets',
		bulletClass: 'bullets__bullet',
		bulletActiveClass: 'bullets__bullet--active',
	},
	navigation: {
		nextEl: '.management__next',
		prevEl: '.management__prev',
		disabledClass: 'management__button-disabled',
	},
	breakpoints: {
		1600: {
			slidesPerView: 4,
		},
		1370: {
			slidesPerView: 4,
			allowTouchMove: false,
		},
		1100: {
			slidesPerView: 3,
		},
		596: {
			slidesPerView: 2,
		},
	},
};
