import React from 'react';
import { NavLink } from 'react-router-dom';
import useStore from '../store/selectors/useStore';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';

interface propsHeaderNavigation {
	className?: string;
	isFocus?: boolean;
	closeDropdown: () => void;
}

const navigation = [
	{
		path: '/about',
		title_ua: 'Про компанію',
		title_en: 'About',
		title_ru: 'О компании',
	},
	{
		path: '/services',
		title_ua: 'Послуги',
		title_en: 'Services',
		title_ru: 'Услуги',
	},
	{
		path: '/projects',
		title_ua: 'Проекти',
		title_en: 'Projects',
		title_ru: 'Проекты',
	},
	{
		path: '/news',
		title_ua: 'Новини',
		title_en: 'News',
		title_ru: 'Новости',
	},
	{
		path: '/vacancies',
		title_ua: 'ВакансіЇ',
		title_en: 'Career',
		title_ru: 'Вакансии',
	},
];

function HeaderNavigation({
	isFocus,
	className,
	closeDropdown,
}: propsHeaderNavigation) {
	const { language } = useStore();

	return (
		<nav
			className={
				isFocus
					? `header-navigation hide ${className}`
					: `header-navigation ${className}`
			}
		>
			<ul className="header-navigation__list">
				{navigation.map((item) => (
					<li key={item.title_ua} className="header-navigation__item">
						<NavLink
							to={item.path}
							onClick={() => closeDropdown()}
							className="header-navigation__button"
						>
							{getLocaleProperty(item, 'title', language)}
						</NavLink>
					</li>
				))}
			</ul>
		</nav>
	);
}

export default HeaderNavigation;
