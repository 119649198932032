import React from 'react';
import Title from './Title';
import { Link } from 'react-router-dom';
import notFound from '../img/404.svg';
import { MAIN } from '../helpers/routes';
import { useDocumentTitle } from '../hooks';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function NotFound() {
	const { t } = useTranslation();

	useDocumentTitle(t('404.error'));

	return (
		<section className="not-found">
			<div className="not-found__body">
				<div className="not-found__image">
					<img src={notFound} alt="" />
				</div>
				<Title size="middle" className="not-found__title">
					{t('404.error')}
				</Title>
				<Link
					to={MAIN}
					className="not-found__button button-link button-link--blue"
				>
					{t('404.to-main')}
				</Link>
			</div>
		</section>
	);
}

export default NotFound;
