import mapProject1 from '../img/projects/mainMap/map-project-1.png';
import mapProject2 from '../img/projects/mainMap/map-project-2.png';
import mapProject3 from '../img/projects/mainMap/map-project-3.png';
import mapProject4 from '../img/projects/mainMap/map-project-4.png';
import mapProject5 from '../img/projects/mainMap/map-project-5.png';

export const mapProjects = [
	{
		thumbnail: mapProject1,
	},
	{
		thumbnail: mapProject2,
	},
	{
		thumbnail: mapProject3,
	},
	{
		thumbnail: mapProject4,
	},
	{
		thumbnail: mapProject5,
	},
];
