export const imageSrc = (project = false) => {
    let w = window.innerWidth;
    let screenSize = 'large'
    if (w < 600) {
        screenSize = 'small'
    }
    if (w > 600 && w < 1100) {
        screenSize = 'medium'
    }
    if ( w > 1100 && project) {
        screenSize = 'original'
    }
    return `${screenSize}`
}