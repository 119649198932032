import React, { useEffect, useState, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ProjectsDropdown from '../components/ProjectsDropdown';
import ProjectsFilterCountry from '../components/ProjectsFilterCountry';
import { PROJECTS } from '../helpers/routes';

import useCountries from '../services/service.countries';
import {
	setCountries,
	resetActiveCountry,
} from '../store/slices/countriesSlice';
import { setProjects } from '../store/slices/projectsSlice';
import useStore from '../store/selectors/useStore';
import useProjects from '../services/service.projects';
import Title from '../components/Title';
import ProjectsCountryItem from '../components/ProjectsCountryItem';
import ProjectsCountryList from '../components/ProjectsCountryList';
import useBindActionCreators from '../hooks/useBindActionCreators';
import noProjects from '../img/projects/no-projects.png';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Projects() {
	const { t } = useTranslation();
	useDocumentTitle(t('projects.title'));
	const getAllCountries = useCountries();
	const { getAllProjects } = useProjects();
	const { projects, filteredCountries, activeCategory, language, activeCountry } = useStore();
	const boundCreators = useBindActionCreators({
		setCountries,
		setProjects,
		resetActiveCountry,
	});
	const {
		setCountries: dispatchCountries,
		setProjects: dispatchProjects,
		resetActiveCountry: resetCountry,
	} = boundCreators;
	const [isEmptyCategory, setIsEmptyCategory] = useState<boolean>(true);

	const handleIsEmptyCategory = useCallback(() => {
		const resultArray: boolean[] = [];
		filteredCountries.forEach((country) => {

			resultArray.push(
				!!projects.filter(
					(project) =>
						project?.project?.category?.translation?.name === activeCategory?.name && 
						project?.project?.country?.translation?.name === country?.name
				).length
			);
		});
		resultArray.every((item: boolean) => !item)
			? setIsEmptyCategory(true)
			: setIsEmptyCategory(false);
	}, [filteredCountries, projects, activeCategory]);

	useEffect(() => {
		handleIsEmptyCategory();
	}, [handleIsEmptyCategory]);

	useEffect(() => {
		if(language && !filteredCountries) {
			getAllCountries(language).then((countries) => {
				dispatchCountries(countries.countries)});;
				getAllProjects(language).then((data) => {
					dispatchProjects(data.projects)});
			resetCountry();
		} else if(language) {
			getAllCountries(language).then((countries) => {
				dispatchCountries(countries.countries)});
			getAllProjects(language).then((data) => {
				dispatchProjects(data.projects)});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	let location = useLocation();


	const visibleFilterCountry =
		location.pathname === `${PROJECTS}/category`
			? 'projects__filter-country hide'
			: 'projects__filter-country';

	return (
		<div className="projects">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="projects__body">
					<div className={visibleFilterCountry}>
						<ProjectsDropdown />
						<ProjectsFilterCountry />
					</div>
					<Outlet />
					{isEmptyCategory ? (
						<div className="projects__category-empty">
							<img src={noProjects} alt="" />
							<p>{t('projects.empty-category')}</p>
						</div>
					) : (
						filteredCountries.map((country) => {
							const data = projects.filter(
								(project) => {
									return 	project?.project?.category?.translation?.name === activeCategory?.name && 
									(project?.project?.country?.translation?.name === country?.name && (activeCountry === 0 ? true : country?.country?.id === activeCountry))
								}
							);
							return (
								country.name !== '' &&
								Boolean(data.length) && (
									<div className="projects-ukraine" key={country.id}>
										<ScrollToTopOnMount />
										<div>
											<Title
												size="small"
												weight="bold"
												className="projects-ukraine__title"
											>
												{country.name}
											</Title>
											<ProjectsCountryList>
												<ProjectsCountryItem data={data} />
											</ProjectsCountryList>
										</div>
									</div>
								)
							);
						})
					)}
				</div>
			</div>
		</div>
	);
}

export default Projects;
