import React from 'react';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';

interface propsMapPoint {
	className?: string;
	project: any;
	country: string;
}

function MapPoint({ className, project, country }: propsMapPoint) {
	return (
		<div className={`${className} map-point map-point--${country}`}>
			<div className="map-point__preview">
				<img src={project.thumbnail || projectEmptyPhoto} alt="" />
			</div>
		</div>
	);
}

export default MapPoint;
