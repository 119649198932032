import useHttp from './httpService';

const useSearch = () => {
	const { getSearch } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`
	const getAllSearch = (
		searchRequest: string,
		locale: string,
		page: number = 1
	) => getSearch(searchRequest, locale, page, "Get", null,
	{'Accept-Language': `${locale}`, 
	'X-Workspace': 'uhp',
	'Access-Control-Allow-Origin': `${_baseUrl}`} );

	return { getAllSearch };
};

export default useSearch;
