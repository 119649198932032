import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks';
import { Link } from 'react-router-dom';
import useStore from '../store/selectors/useStore';

import logo from '../img/logo.svg';
import UHP from '../img/otherLogoCompany/UHP.svg';
import UAEP from '../img/otherLogoCompany/UAEP.svg';
import ICER from '../img/otherLogoCompany/ICER.svg';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';

const companies = [
	{
		path: '/',
		image: UAEP,
		type_ua: 'ТОВ',
		type_en: 'Ltd',
		type_ru: 'ООО',
		name_ua: '«УКРАТОМЕНЕРГОПРОЕКТ»',
		name_en: 'Ukratomenergoproject',
		name_ru: '«УКРАТОМЭНЕРГОПРОЕКТ»',
		link: 'http://www.uaep.com.ua/',
		id: 1,
	},
	{
		path: '/',
		image: logo,
		type_ua: 'ТОВ',
		type_en: 'Ltd',
		type_ru: 'ООО',
		name_ua: '«ГІДРОТЕХПРОЕКТ»',
		name_en: 'Hydrotechproject',
		name_ru: '«ГИДРОТЕХПРОЕКТ»',
		link: 'http://htp.kharkov.ua/',
		id: 3,
	},
	{
		path: '/',
		image: ICER,
		type_ua: 'ТОВ',
		type_en: 'Ltd',
		type_ru: 'ООО',
		name_ua: '«МІЖВІДОМЧИЙ ЦЕНТР ІНЖЕНЕРНИХ ДОСЛІДЖЕНЬ»',
		name_en: 'Interdepartmental Centre of Engineering Researches',
		name_ru: '«МЕЖВЕДОМСТВЕННЫЙ ЦЕНТР ИНЖНЕНЕРНЫХ ИЗЫСКАНИЙ»',
		link: 'http://mcii.kharkov.ua/',
		id: 4,
	},
];

function LogoDropdown() {
	const { language } = useStore();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const refDropdown = useRef<HTMLButtonElement>(null);

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	useOnClickOutside(refDropdown, handleCloseDropdown);

	return (
		<div className={isDropdownOpen ? 'logo-dropdown open' : 'logo-dropdown'}>
			<div className="logo-dropdown__body">
				<div className="logo-dropdown__logo">
					<Link to="/" className="logo-dropdown__logo-link">
						<img src={UHP} alt="Logo" />
					</Link>
					<button
						ref={refDropdown}
						className="logo-dropdown__logo-arrow"
						onClick={() => {
							isDropdownOpen
								? setIsDropdownOpen(false)
								: setIsDropdownOpen(true);
						}}
					/>
				</div>

				<ul className="logo-dropdown__list">
					{companies.map((company) => (
						<li key={company.id} className="logo-dropdown__item">
							<a
								href={company?.link}
								target="_blank"
								rel="noreferrer"
								className="logo-dropdown__link"
							>
								<div className="logo-dropdown__item-logo">
									<img src={company.image} alt="UHP" />
								</div>
								<div className="logo-dropdown__item-name">
									{getLocaleProperty(company, 'type', language)}{' '}
									<b>{getLocaleProperty(company, 'name', language)}</b>
								</div>
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default LogoDropdown;
