import React from 'react';
import { Link } from 'react-router-dom';
import { propsNewsList } from '../typings/news';
import useStore from '../store/selectors/useStore';

import { useTranslation } from 'react-i18next';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';
import { imageSrc } from '../helpers/imageSrc';
import { NEWS } from '../helpers/routes';
import useRedirectToItem from '../hooks/useRedirectToItem';

function NewsList({ data }: propsNewsList) {
	const { language } = useStore();
	const { t } = useTranslation();

	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;

	let imgSrc = imageSrc();
	let src = (item) => {
		return `${item?.image[imgSrc]?.path}`;
	};
	const redirect = useRedirectToItem();

	return (
		<ul className="news-list">
			{data.map((item, index) => {
				return (
					<li
						key={index}
						className="news-list__item"
						onClick={() => redirect(NEWS, item.slug)}
					>
						<div className="news-list__image">
							<img src={`${_baseUrl}${src(item)}`} alt="" />
						</div>
						<div className="news-list__content">
							<span className="news-list__date">
								{item.article.updatedAt
									.slice(0, 10)
									.split('-')
									.reverse()
									.join('-')
									.replaceAll('-', '.')}
							</span>
							<h3 className="news-list__title">{item.title}</h3>
							<div
								className="news-list__description"
								contentEditable="false"
								dangerouslySetInnerHTML={{
									__html: item?.body,
								}}
							/>
							<div
								className="news-list__button button-link button-link--blue"
							>
								{t('news.buttons.read-more')}
							</div>
						</div>
					</li>
				);
			})}
		</ul>
	);
}

export default NewsList;
