import { useCallback } from 'react';
import useHttp from './httpService';

const useVacancies = () => {
	const { getOne, getAll100 } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`
	const getAllVacancies = useCallback(
		(language, workspace) => getAll100('vacancy','GET', null,
		{'Accept-Language': `${language}`, 
		'X-Workspace': `${workspace}`,
		'Access-Control-Allow-Origin': `${_baseUrl}`}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getAll100]
	);
	const getVacancy = useCallback(
		(vacancyId: string, language,) => getOne(`/vacancy/translation/${vacancyId}/get`, 'GET', null,
		{'Accept-Language': `${language}`, 
		'Access-Control-Allow-Origin': `${_baseUrl}`}),
		[getOne]
	);

	return {
		getAllVacancies,
		getVacancy,
	};
};

export default useVacancies;
