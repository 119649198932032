import { createSlice } from "@reduxjs/toolkit";
import { newsType } from "../../typings/news";

const initialState: newsType = {
    news: []
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNews: (state, action) => {
            state.news = action.payload;
        }
    }
});

const { actions, reducer: newsReducer } = newsSlice;
export const { setNews } = actions;
export default newsReducer;