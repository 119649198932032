import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks';
import useBindActionCreators from '../hooks/useBindActionCreators';
import BurgerButton from './BurgerButton';
import ContactButton from './ContactButton';
import ContactDropdown from './ContactDropdown';
import { setIsOpenContactDropdown } from '../store/slices/contactDropdownSlice';
import useStore from '../store/selectors/useStore';

interface propContact {
	className?: string;
}

function Contact({ className }: propContact) {
	const { contactDropdown } = useStore();
	const boundCreators = useBindActionCreators({
		setIsOpenContactDropdown,
	});
	const { setIsOpenContactDropdown: dispatchIsOpenContactDropdown } =
		boundCreators;

	const refConctactDropdown = useRef<HTMLDivElement>(null);

	const handleCloseDropdown = () => {
		dispatchIsOpenContactDropdown(false);
	};

	useOnClickOutside(refConctactDropdown, handleCloseDropdown);

	return (
		<div ref={refConctactDropdown} className={`contact ${className}`}>
			<div className="contact__body">
				<ContactButton
					className="contact__button"
					openDropdown={() => dispatchIsOpenContactDropdown(true)}
				/>
				<BurgerButton
					className="contact__burger-button"
					openDropdown={() => dispatchIsOpenContactDropdown(true)}
					closeDropdown={() => dispatchIsOpenContactDropdown(false)}
					isOpenDropdown={contactDropdown}
					refConctactDropdown={refConctactDropdown}
				/>
				<ContactDropdown
					className="contact__dropdown"
					isOpenDropdown={contactDropdown}
					closeDropdown={() => dispatchIsOpenContactDropdown(false)}
				/>
			</div>
		</div>
	);
}

export default Contact;
