import React, { ReactNode } from 'react';
import iconPhone from '../img/icons/phone.svg';
import iconMail from '../img/icons/mail.svg';
import ContactSocial from './ContactSocial';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsFooterContact {
	className?: string;
	children?: ReactNode;
}

function FooterContact({ className, children }: propsFooterContact) {
	const { t } = useTranslation();

	return (
		<div className={`footer-contact ${className}`}>
			<div className="footer-contact__body">
				{children}
				<ul className="footer-contact__list">
					<li className="footer-contact__item">
						<address className="footer-contact__address">
							{t('footer.contact.address.first-part')}
							<br /> {t('footer.contact.address.second-part')}
						</address>
					</li>
					<li className="footer-contact__item">
						<a href="tel:+380577021507" className="footer-contact__link">
							<img
								src={iconPhone}
								alt="phone"
								className="footer-contact__icon"
							/>
							+38 057 702-15-07
						</a>
					</li>
					{/* <li className="footer-contact__item">
						<a href="tel:+380577194730" className="footer-contact__link">
							<img
								src={iconPhone}
								alt="phone"
								className="footer-contact__icon"
							/>
							{t('footer.contact.fax')}: +38 (057) 719-47-30
						</a>
					</li> */}
					<li className="footer-contact__item">
						<a
							href="mailto:water@uhp.kharkov.ua"
							className="footer-contact__link"
						>
							<img
								src={iconMail}
								alt="phone"
								className="footer-contact__icon"
							/>
							water@uhp.kharkov.ua
						</a>
					</li>
					<li className="footer-contact__item">
						<ContactSocial className="footer-contact__social" />
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FooterContact;
