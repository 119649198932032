import React from 'react';
import Title from './Title';
import ManagementInfo from './ManagementInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { management } from '../data/management';
import { managementSliderSettings } from '../helpers/managementSliderSettings';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsManagement {
	titleSize: 'big' | 'middle';
	className?: string;
}

function Management({ titleSize, className }: propsManagement) {
	const { t } = useTranslation();

	const managementData = t('management.managementData', {
		returnObjects: true,
	});

	return (
		<section className={`management ${className}`}>
			<div id="management" className="container">
				<div className="management__body">
					<Title size={titleSize} className="management__title">
						{t('management.title')}
					</Title>
					<Swiper
						{...managementSliderSettings}
						modules={[Pagination, Autoplay, Navigation]}
						className="management__list"
					>
						{Array.from(managementData).map((item: any, index) => {
							return (
								<SwiperSlide key={index}>
									<ManagementInfo
										key={index}
										className="management__item"
										photo={management[index].photo}
										fullName={item.fullName}
										position={item.position}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className="management__navigation">
						<button type="button" className="management__prev" />
						<button type="button" className="management__next" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Management;
