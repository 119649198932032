export const scrollToAnchor = (anchorName: string) => {
	if (anchorName) {
		let anchorElement = document.getElementById(anchorName);

		if (anchorElement) {
			anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
		}

		if (anchorElement) {
			const yOffset = -80;
			const y =
				anchorElement.getBoundingClientRect().top +
				window.pageYOffset +
				yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	}
};
