import React from 'react';
import ContactSocial from './ContactSocial';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactMarketing {
	className?: string;
}

function ContactFax({ className }: propsContactMarketing) {
	const { t } = useTranslation();

	return (
		<div className={`contact-fax ${className}`}>
			<ul className="contact-fax__list">
				{/* <li className="contact-fax__item">
					<a href="tel:+380577194730">
						{t('footer.contact.fax')}: +38 (057) 719-47-30
					</a>
				</li> */}

				<li className="contact-fax__item">
					<ContactSocial />
				</li>
			</ul>
		</div>
	);
}

export default ContactFax;
