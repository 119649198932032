import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PROJECTS } from '../helpers/routes';
import Title from './Title';
import ButtonLink from './ButtonLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import useProjects from '../services/service.projects';
import useBindActionCreators from '../hooks/useBindActionCreators';
import { setProjects } from '../store/slices/projectsSlice';
import useStore from '../store/selectors/useStore';
import useRedirectToItem from '../hooks/useRedirectToItem';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { imageSrc } from '../helpers/imageSrc';
import {project as Project} from '../../src/typings/projects'
import {projects as Projects} from '../../src/typings/projects'

interface propsMainProjects {
	className?: string;
}

const settings = {
	centeredSlides: true,
	speed: 500,
	watchSlidesProgress: true,
	autoplay: true,
	spaceBetween: 0,
	simulateTouch: false,
	slideActiveClass: 'main-projects__slide-active',
	slidePrevClass: 'main-projects__slide-prev',
	slideNextClass: 'main-projects__slide-next',
	slideVisibleClass: 'main-projects__slide-visible',
	slideToClickedSlide: true,
	loop: true,
	loopedSlides: 3,
	navigation: {
		nextEl: '.main-projects__prev',
		prevEl: '.main-projects__next',
	},
	pagination: {
		clickable: true,
		bulletElement: 'span',
		clickableClass: 'bullets',
		bulletClass: 'bullets__bullet',
		bulletActiveClass: 'bullets__bullet--active',
	},
};

function MainProjects({ className }: propsMainProjects) {
	const { getAllProjects } = useProjects();

	const { projects, language } = useStore();

	const { setProjects: dispatchProjects } = useBindActionCreators({
		setProjects,
	});
	const redirect = useRedirectToItem();
	const [mainProjectsSlider, setMainProjectsSlider] = useState<Projects>([])
	const { t } = useTranslation();

	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`

	let imgSrc = imageSrc(true)
	let src = (project) => {
		return `${project?.images[0]?.image[imgSrc]?.path}`
	}

	useEffect(() => {
		getAllProjects(language).then((data) => {
			const dniproHES = data.projects.find((project :Project) => project.project.id === "104") || {}
			const kyivHES = data.projects.find((project :Project) => project.project.id === "54") || {}
			const dnistroHAES = data.projects.find((project :Project) => project.project.id === "58") || {}
			const hesMtktavri = data.projects.find((project :Project) => project.project.id === "66") || {}
			const namChienHPP =  data.projects.find((project :Project) => project.project.id === "97") || {}
			setMainProjectsSlider([dniproHES,kyivHES,dnistroHAES, hesMtktavri, namChienHPP])
		}
		);
	}, [language]);

	return (
		<section className={`main-projects ${className}`}>
			<div className="container">
				<div className="main-projects__wrapper">
					<div className="main-projects__header">
						<Title size="middle" className="main-projects__title">
							{t('main.projects.title')}
						</Title>
						<Link
							to={PROJECTS}
							className="main-projects__button button-link button-link--red"
						>
							{t('main.projects.see-all')}
						</Link>
					</div>

					<div className="main-projects__body">
						<Swiper
							{...settings}
							slidesPerView="auto"
							modules={[Pagination, Autoplay, Navigation]}
							className="main-projects__slider"
						>
							{mainProjectsSlider?.map((project, index) => {
								return (
									<SwiperSlide key={index} className="main-projects__slide">
										<div className="main-projects__slide-body">
											<h6 className="main-projects__slide-title">
												{project.title}
											</h6>
											<div className="main-projects__slide-image">
												<img
													src={project?.images?.length ? `${_baseUrl}${src(project)}` : projectEmptyPhoto}
													alt='project'
												/>
											</div>
											<div className="main-projects__slide-footer">
												<ButtonLink
													onClick={() => redirect(PROJECTS, project.slug as unknown as number)}
													color="black"
													className="main-projects__slide-button"
												>
													{t('main.buttons.to-project')}
												</ButtonLink>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>
						<div className="main-projects__navigation">
							<button type="button" className="main-projects__next" />
							<button type="button" className="main-projects__prev" />
						</div>
					</div>
					<Link
						to={PROJECTS}
						className="main-projects__button button-link button-link--red main-projects__button--mobile"
					>
						{t('main.projects.see-all')}
					</Link>
				</div>
			</div>
		</section>
	);
}

export default MainProjects;
