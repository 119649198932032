import { combineReducers } from 'redux';
import categoriesReducer from './slices/categoriesSlice';
import countriesReducer from './slices/countriesSlice';
import documentsReducer from './slices/documentsSlice';
import newsReducer from './slices/newsSlice';
import projectsReducer from './slices/projectsSlice';
import vacanciesReducer from './slices/vacanciesSlice';
import languageReducer from './slices/languageSlice';
import contactDropdownReducer from './slices/contactDropdownSlice';
import vacancyReducer from './slices/vacancySlice';
import titleReducer from './slices/titleSlice';

const rootReducer = combineReducers({
	vacancies: vacanciesReducer,
	news: newsReducer,
	categories: categoriesReducer,
	countries: countriesReducer,
	projects: projectsReducer,
	documents: documentsReducer,
	language: languageReducer,
	contactDropdown: contactDropdownReducer,
	vacancy: vacancyReducer,
	title: titleReducer
});

export default rootReducer;
