import React, { useState, useRef, RefObject, useEffect } from 'react';
import { useOnClickOutside } from '../hooks';
import useStore from '../store/selectors/useStore';

interface propsBurgerButton {
	className?: string;
	isOpenDropdown?: boolean;
	refConctactDropdown: RefObject<HTMLDivElement>;
	openDropdown: () => void;
	closeDropdown: () => void;
}

function BurgerButton({
	className,
	refConctactDropdown,
	openDropdown,
	closeDropdown,
}: propsBurgerButton) {
	const { contactDropdown } = useStore();

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [isActive, setIsActive] = useState(contactDropdown);

	const toggleActiveState = () => {
		contactDropdown ? closeDropdown() : openDropdown();
	};

	const handleInActive = () => {
		setIsActive(false);
	};

	useEffect(() => {
		setIsActive(contactDropdown);
	}, []);

	useOnClickOutside(refConctactDropdown, handleInActive);

	return (
		<button
			type="button"
			ref={buttonRef}
			onClick={() => toggleActiveState()}
			className={
				contactDropdown
					? `burger-button active ${className}`
					: `burger-button ${className}`
			}
		>
			<span></span>
			<span></span>
			<span></span>
		</button>
	);
}

export default BurgerButton;
