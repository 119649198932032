import React from 'react';
import MapSVG from './MapSVG';
import Title from './Title';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsMainMap {
	className?: string;
}

function MainMap({ className }: propsMainMap) {
	const { t } = useTranslation();

	return (
		<section className={`main-map ${className}`}>
			<div className="container">
				<div className="main-map__body">
					<div className="main-map__info">
						<Title size="middle" className="main-map__title">
							{t('main.map.title')}
						</Title>
						<div className="main-map__description">
							{t('main.map.description.first-part')}
						</div>
						<div className="main-map__country">
							{t('main.map.description.second-part')}
						</div>
					</div>
					<div className="main-map__world">
						<MapSVG className="main-map__svg" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default MainMap;
