import React, { ReactNode } from 'react';
import iconPhone from '../img/icons/phone.svg';
import iconMail from '../img/icons/mail.svg';

interface propsFooterMarketing {
	className?: string;
	children?: ReactNode;
}

function FooterMarketing({ className, children }: propsFooterMarketing) {
	return (
		<div className={`footer-marketing ${className}`}>
			<div className="footer-marketing__body">
				{children}
				<ul className="footer-marketing__list">
					<li className="footer-marketing__item">
						<a href="tel:+380577021518" className="footer-marketing__link">
							<img
								src={iconPhone}
								alt="phone"
								className="footer-marketing__icon"
							/>
							+38 057 702-15-18
						</a>
					</li>
					<li className="footer-marketing__item">
						<a
							href="mailto:marketing@uhp.kharkov.ua"
							className="footer-marketing__link"
						>
							<img
								src={iconMail}
								alt="phone"
								className="footer-marketing__icon"
							/>
							marketing@uhp.kharkov.ua
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FooterMarketing;
